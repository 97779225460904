<template>
  <div class="order_list">
    <van-sticky>
      <van-nav-bar
        title="我的地址"
        left-text="返回"
        @click-left="onClickLeft"
        left-arrow
      />
    </van-sticky>
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      chosenAddressId: "1",
      list: [
        {
          id: "1",
          name: "张三",
          tel: "13000000000",
          address: "浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室",
          isDefault: true,
        },
        {
          id: "2",
          name: "李四",
          tel: "1310000000",
          address: "浙江省杭州市拱墅区莫干山路 50 号",
        },
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onAdd() {
      this.$toast("新增地址");
    },
    onEdit(item, index) {
      this.$toast("编辑地址:" + index);
      this.$router.push("/address/edit");
    },
  },
};
</script>

<style lang="less" scoped>
.order_list {
  background: #f9f9f9;
  height: 92vh;
  .van-nav-bar /deep/ .van-icon,
  .van-nav-bar /deep/.van-nav-bar__text {
    color: #c03131;
  }
}
</style>
